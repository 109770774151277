// LandingPage.js
import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import FileUpload from './FileUpload';
import ResultsTable from './ResultsTable';
import FeatureCards from './FeatureCards';
import { useAuth } from '../hooks/useAuth';

export default function LandingPage() {
  const [calculationResults, setCalculationResults] = useState(null);
  const [error, setError] = useState(null);
  const [showUpload, setShowUpload] = useState(true);

  const { isLoggedIn, username, isAdmin, loading, handleLogout, handleLogin } = useAuth();  // Get loading state

  const handleUploadSuccess = (results) => {
    setCalculationResults(results);
    setShowUpload(false);
  };

  const handleTryAgain = () => {
    setCalculationResults(null);
    setError(null);
    setShowUpload(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800">
      <Header
        isLoggedIn={isLoggedIn}
        username={username}
        onLogout={handleLogout}
        onLogin={handleLogin}
        isAdmin={isAdmin}
        loading={loading}  // Pass loading prop to Header
      />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
        {showUpload && (
          <>
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Calculate Your Gender Pay Gap</h2>
            <p className="text-xl mb-4 text-gray-600">
              Upload your CSV file and get instant results. Ensure compliance and promote equality.
            </p>
          </>
        )}

        <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl mx-auto">
          {showUpload ? (
            <FileUpload isLoggedIn={isLoggedIn} onUploadSuccess={handleUploadSuccess} setError={setError} />
          ) : (
            <>
              <div className="flex items-center justify-center mb-6">
                <h2 className="text-3xl font-bold text-gray-900">Your Results</h2>
              </div>
              <ResultsTable results={calculationResults} />
              <button className="w-full text-lg py-6 bg-green-600 hover:bg-green-700 text-white mt-6" onClick={handleTryAgain}>
                Try Again with a New File
              </button>
            </>
          )}
          {error && (
            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              <p>{error}</p>
            </div>
          )}
        </div>

        {showUpload && <FeatureCards />}
      </main>

      <Footer />
    </div>
  );
}