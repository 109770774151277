import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './app/components/LandingPage';
import Admin from './app/components/Admin';
import ProtectedRoute from './app/components/ProtectedRoute'
import About from './app/components/About';
import Contact from './app/components/Contact';
import FAQ from './app/components/FAQ';
import PrivacyPolicy from './app/components/PrivacyPolicy';
import TermsOfService from './app/components/TermsOfService';
import Support from './app/components/Support';


function App() {

  // const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  // const [isAdmin, setIsAdmin] = React.useState(false)

  // // Function to check if the user is logged in and is an admin
  // const checkAuth = () => {
  //   const token = localStorage.getItem('token')
  //   if (token) {
  //     // Here you would typically verify the token with your backend
  //     // For this example, we'll just check if it exists
  //     setIsLoggedIn(true)
  //     // Check if the user is an admin (this should be based on the user's role from the backend)
  //     setIsAdmin(localStorage.getItem('userRole') === 'admin')
  //   }
  // }

  // React.useEffect(() => {
  //   checkAuth()
  // }, [])

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute isAllowed={true} requiresAdmin={true}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path="/about"
            element={
              <About />
            } />
          <Route path="/contact"
            element={
              <Contact />
            } />
          <Route path="/faq"
            element={
              <FAQ />
            } />
          <Route path="/privacy-policy"
            element={
              <PrivacyPolicy />
            } />
                            <Route path="/terms-of-service"
        element={
          <TermsOfService />
        }/>
                                    <Route path="/support"
        element={
          <Support />
        }/>

        </Routes>
      </div>
    </Router>
  );
}

export default App;