import React from 'react'
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Facebook, Twitter, Linkedin, Mail } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function Footer() {
  const handleNewsletterSubmit = (e) => {
    e.preventDefault()
    // TODO: Implement newsletter signup logic
    console.log('Newsletter signup submitted')
  }

  return (
    <footer className="bg-white shadow-sm mt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-blue-600">Company</h3>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-600 hover:text-blue-600">About Us</Link></li>
              <li><Link to="/contact" className="text-gray-600 hover:text-blue-600">Contact Us</Link></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-blue-600">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy-policy" className="text-gray-600 hover:text-blue-600">Privacy Policy</Link></li>
              <li><Link to="/terms-of-service" className="text-gray-600 hover:text-blue-600">Terms of Service</Link></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-blue-600">Resources</h3>
            <ul className="space-y-2">
              <li><Link to="/faq" className="text-gray-600 hover:text-blue-600">FAQ</Link></li>
              <li><Link to="/support" className="text-gray-600 hover:text-blue-600">Support</Link></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-blue-600">Newsletter</h3>
            <form onSubmit={handleNewsletterSubmit} className="space-y-2">
              <Input
                type="email"
                placeholder="Enter your email"
                required
                className="w-full"
              />
              <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">Subscribe</Button>
            </form>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200">
          <div className="flex flex-wrap justify-between items-center">
            <p className="text-gray-600">&copy; {new Date().getFullYear()} Pay Gap Calculator. All rights reserved.</p>
            {/* <div className="flex space-x-4 mt-4 md:mt-0">
              <a href="#" className="text-gray-400 hover:text-blue-600"><Facebook size={20} /></a>
              <a href="#" className="text-gray-400 hover:text-blue-600"><Twitter size={20} /></a>
              <a href="#" className="text-gray-400 hover:text-blue-600"><Linkedin size={20} /></a>
              <a href="#" className="text-gray-400 hover:text-blue-600"><Mail size={20} /></a>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  )
}