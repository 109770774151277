import React, { useState } from 'react'
import { ChevronDown } from 'lucide-react'

export const AccordionItem = ({ children, value, trigger, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-gray-200">
      <button
        className="flex justify-between items-center w-full py-4 text-left text-gray-800 hover:bg-gray-50 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium">{trigger}</span>
        <ChevronDown
          className={`w-5 h-5 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="pb-4 pt-2 px-4">
          {content}
        </div>
      )}
    </div>
  )
}

export const Accordion = ({ children, type = "single", collapsible = true }) => {
  return <div className="divide-y divide-gray-200">{children}</div>
}