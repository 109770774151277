import React from 'react'
import { UploadCloud, Zap, Lock } from 'lucide-react'

export default function FeatureCards() {
  return (
    <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8">
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center justify-center mb-4">
          <UploadCloud className="w-10 h-10 text-blue-600" />
        </div>
        <h3 className="text-xl font-semibold mb-4 text-gray-800">Easy Upload</h3>
        <p className="text-gray-600">Simply drag and drop your CSV file or click to select it from your computer.</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center justify-center mb-4">
          <Zap className="w-10 h-10 text-yellow-500" />
        </div>
        <h3 className="text-xl font-semibold mb-4 text-gray-800">Instant Results</h3>
        <p className="text-gray-600">Get your gender pay gap calculation results immediately after uploading your file.</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center justify-center mb-4">
          <Lock className="w-10 h-10 text-green-600" />
        </div>
        <h3 className="text-xl font-semibold mb-4 text-gray-800">Secure & Private</h3>
        <p className="text-gray-600">Your data is processed securely and never stored on our servers without your permission.</p>
      </div>
    </div>
  )
}