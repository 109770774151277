import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { useAuth } from '../hooks/useAuth'

export default function Layout({ children }) {
  const { isLoggedIn, username, isAdmin, loading, handleLogout, handleLogin } = useAuth();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800">
      <Header
        isLoggedIn={isLoggedIn}
        username={username}
        onLogout={handleLogout}
        onLogin={handleLogin}
        isAdmin={isAdmin}
        loading={loading}
      />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  )
}