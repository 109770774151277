import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ children, isAllowed, requiresAdmin = false }) => {
  const location = useLocation();
  const { isLoggedIn, isAdmin, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // Show loading state while checking authentication
  }

  // If user is not logged in or not allowed
  if (!isLoggedIn || !isAllowed) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If admin access is required but the user is not an admin
  if (requiresAdmin && !isAdmin) {
    return <Navigate to="/" replace />; // Redirect non-admins to home page
  }

  return children;
};

export default ProtectedRoute;