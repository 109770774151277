import React from 'react'
import { ArrowRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from "../ui/button"
import Layout from './Layout'
export default function TermsOfService() {
  return (
    <Layout>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Terms of Service</h1>
      
      <div className="space-y-8 text-gray-700">
        <p>
          Effective Date: {new Date().toLocaleDateString()}
        </p>

        <p>
          Welcome to Gender Pay Gap Calculator! These Terms of Service ("Terms") govern your access to and use of our platform, products, and services (collectively, the "Services") provided by Gender Pay Gap Calculator ("Company," "we," "our," or "us"). By using our Services, you agree to these Terms. If you do not agree, please refrain from using our Services.
        </p>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">1. Acceptance of Terms</h2>
          <p>
            By accessing or using our Services, you agree to be bound by these Terms, our Privacy Policy, and all applicable laws and regulations. You also agree to be responsible for ensuring that your use of the Services complies with any applicable local laws.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">2. Eligibility</h2>
          <p>
            You must be at least 18 years old or the age of majority in your jurisdiction to use the Services. By using our Services, you warrant that you have the legal authority to enter into this agreement and comply with these Terms.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">3. Account Registration and Security</h2>
          <p>
            To access certain features of the Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account and password and for any activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">4. Services Description</h2>
          <p>
            Our platform is designed to calculate gender pay gap information by analyzing employee compensation data. You understand that the accuracy of the analysis and reports generated by the platform depends on the quality and completeness of the data you provide.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">5. User Responsibilities</h2>
          <ul className="list-disc pl-5 space-y-2">
            <li>You are responsible for providing accurate, complete, and up-to-date data.</li>
            <li>You agree not to use the Services for any illegal, harmful, or unethical purposes.</li>
            <li>You are solely responsible for ensuring that your use of the Services complies with applicable employment and privacy laws, including but not limited to data protection regulations (e.g., UK GDPR, Data Protection Act 2018).</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">6. Consumer Rights</h2>
          <p>
            If you are using our Services as a consumer, nothing in these Terms will affect your statutory rights under UK consumer law. This includes your rights to receive services of satisfactory quality, fit for purpose, and as described.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">7. Business Use Only</h2>
          <p>
            The Services are intended for business users only, and by using the Services, you confirm that you are not acting as a consumer. If you are using our Services as a consumer, please contact us, as different terms may apply.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">8. Prohibited Activities</h2>
          <p>You agree not to:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Engage in any unlawful, abusive, or fraudulent activities.</li>
            <li>Access or use the Services to reverse engineer, decompile, or copy the platform or its functionality.</li>
            <li>Interfere with or disrupt the integrity of the Services.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">9. Data Protection and Privacy</h2>
          <p>
            We comply with the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018 in handling your personal data. By using the Services, you agree to our collection and processing of personal data as outlined in our Privacy Policy.
          </p>
          <p className="mt-2">
            You must ensure that you have the necessary consents and legal basis to provide us with any personal data of your employees or other individuals that you input into the platform.
          </p>
          <p className="mt-2">
            You agree that you are the data controller in respect of the personal data you upload, and we are a data processor. Our responsibilities and obligations as a data processor are governed by the Data Processing Agreement.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">10. Fees and Payment</h2>
          <p>
            [Describe your pricing structure, subscription models, and any refund policies here.]
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">11. Intellectual Property</h2>
          <p>
            All intellectual property rights related to the Services, including trademarks, logos, and software, belong to Gender Pay Gap Calculator. You may not use or reproduce any intellectual property without our prior written consent.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">12. Disclaimer of Warranties</h2>
          <p>
            We provide the Services "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">13. Limitation of Liability</h2>
          <p>Nothing in these Terms excludes or limits our liability for:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li>Death or personal injury caused by our negligence;</li>
            <li>Fraud or fraudulent misrepresentation;</li>
            <li>Any other liability that cannot be excluded or limited by UK law.</li>
          </ul>
          <p className="mt-2">
            To the fullest extent permitted by law, we shall not be liable for any indirect or consequential losses, loss of profits, loss of data, or damages arising out of your use of the Services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">14. Cancellation and Refunds</h2>
          <p>
            If you are a consumer, you have the right to cancel your use of the Services within 14 days of entering into a contract with us, provided that you have not begun using the Services. If you choose to waive your cooling-off rights and start using the Services immediately, no refund will be provided for any cancellation.
          </p>
          <p className="mt-2">
            If you are a business user, cancellation and refunds will be governed by the terms of your specific subscription plan.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">15. Termination</h2>
          <p>
            We may suspend or terminate your access to the Services at our sole discretion, with or without notice, if you violate these Terms or any applicable laws, or if we discontinue the Services.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">16. Modifications to the Terms</h2>
          <p>
            We may revise these Terms at any time by posting an updated version on our website. Your continued use of the Services after the changes take effect constitutes your acceptance of the revised Terms.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">17. Governing Law and Jurisdiction</h2>
          <p>
            These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these Terms or your use of the Services shall be subject to the exclusive jurisdiction of the courts of England and Wales.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">18. Contact Information</h2>
          <p>
            For any questions or concerns about these Terms, please contact us at:
          </p>
          <p className="mt-2">
            Gender Pay Gap Calculator<br />
            contact@genderpaygapcalculator.com<br />
            123 Equality Street, Fairness City, FC 12345, United Kingdom
          </p>
        </section>

        <div className="text-center mt-12">
          <Link to="/contact">
            <Button className="bg-blue-600 hover:bg-blue-700 text-white">
              Contact Us <ArrowRight className="ml-2" size={20} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
    </Layout>
  )
}