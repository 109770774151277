import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../hooks/useAuth';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Trash2 } from 'lucide-react';
import { Button } from "../ui/button";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../ui/confirm';

export default function Admin() {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: 'user' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  const { isLoggedIn, username, isAdmin, loading, handleLogout, handleLogin } = useAuth(); // Get the loading state
  
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isLoggedIn) {
      // If not logged in, navigate to the landing page
      navigate('/');
    } else if (!loading && isLoggedIn && isAdmin) {
      // Only fetch users when the authentication is confirmed and user is an admin
      fetchUsers();
    }
  }, [loading, isLoggedIn, isAdmin, navigate]);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        navigate('/');
        return;
      }
      const response = await axios.get(`${API_BASE_URL}/api/admin/users`, {
        headers: { 'x-auth-token': token }
      });
      setUsers(response.data); // Set the users from response
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        navigate('/');
      } else {
        setError('Error fetching users. Please try again.');
        console.error('Error fetching users:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }
      await axios.post(`${API_BASE_URL}/api/admin/users`, newUser, {
        headers: { 'x-auth-token': token }
      });
      setNewUser({ name: '', email: '', password: '', role: 'user' });
      setSuccess('User added successfully');
      fetchUsers(); // Refetch users after adding new one
    } catch (error) {
      setError('Error adding user. Please try again.');
      console.error('Error adding user:', error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }
      await axios.delete(`${API_BASE_URL}/api/admin/users/${userId}`, {
        headers: { 'x-auth-token': token }
      });
      setSuccess('User deleted successfully');
      fetchUsers(); // Refetch users after deleting one
    } catch (error) {
      setError('Error deleting user. Please try again.');
      console.error('Error deleting user:', error);
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }
      await axios.put(`${API_BASE_URL}/api/admin/users/${userId}`, { role: newRole }, {
        headers: { 'x-auth-token': token }
      });
      setSuccess('User role updated successfully');
      fetchUsers(); // Refetch users after updating the role
    } catch (error) {
      setError('Error updating user role. Please try again.');
      console.error('Error updating user role:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading state while checking authentication
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800">
      <Header isLoggedIn={isLoggedIn} username={username} onLogout={handleLogout} onLogin={handleLogin} isAdmin={isAdmin} />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h1 className="text-4xl font-bold mb-6 text-gray-900 text-center">Admin Dashboard</h1>

        <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-900">Add New User</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={newUser.name}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={newUser.email}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div>
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
              <select
                id="role"
                name="role"
                value={newUser.role}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <Button type="submit" className="w-full">
              Add User
            </Button>
          </form>
          {error && (
            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              <p>{error}</p>
            </div>
          )}
          {success && (
            <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
              <p>{success}</p>
            </div>
          )}
        </div>

        <div className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-900">User List</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user._id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Select
                        value={user.role}
                        onValueChange={(newRole) => handleRoleChange(user._id, newRole)}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select a role" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="user">User</SelectItem>
                          <SelectItem value="admin">Admin</SelectItem>
                        </SelectContent>
                      </Select>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <ConfirmDialog
                        triggerText={<Trash2 className="h-4 w-4" />}
                        title="Delete User"
                        description="Are you sure you want to delete this user? This action cannot be undone."
                        confirmText="Delete"
                        onConfirm={() => handleDelete(user._id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}