import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table"
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card"

export default function ResultsTable({ results }) {
  const formatValue = (value) => {
    if (value === null || value === undefined) {
      return 'N/A'
    }
    return value.toFixed(1) + '%'
  }

  const defaultResults = {
    meanHourlyPayGap: 0,
    medianHourlyPayGap: 0,
    meanBonusGap: 0,
    medianBonusGap: 0,
    malesBonusPercentage: 0,
    femalesBonusPercentage: 0,
    upperQuartileMale: 0,
    upperQuartileFemale: 0,
    upperMiddleQuartileMale: 0,
    upperMiddleQuartileFemale: 0,
    lowerMiddleQuartileMale: 0,
    lowerMiddleQuartileFemale: 0,
    lowerQuartileMale: 0,
    lowerQuartileFemale: 0,
  }

  const safeResults = { ...defaultResults, ...results }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">Gender Pay Gap Results</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-2/3">Metric</TableHead>
                <TableHead className="w-1/3 text-right">Value</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-medium">Mean gender pay gap in hourly pay</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.meanHourlyPayGap)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Median gender pay gap in hourly pay</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.medianHourlyPayGap)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Mean bonus gender pay gap</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.meanBonusGap)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Median bonus gender pay gap</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.medianBonusGap)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Proportion of males receiving a bonus payment</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.malesBonusPercentage)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium">Proportion of females receiving a bonus payment</TableCell>
                <TableCell className="text-right">{formatValue(safeResults.femalesBonusPercentage)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-medium" colSpan={2}>
                  Proportion of males and females in each pay quartile
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="pl-8">Upper quartile</TableCell>
                <TableCell className="text-right">
                  M: {formatValue(safeResults.upperQuartileMale)}, F: {formatValue(safeResults.upperQuartileFemale)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="pl-8">Upper middle quartile</TableCell>
                <TableCell className="text-right">
                  M: {formatValue(safeResults.upperMiddleQuartileMale)}, F: {formatValue(safeResults.upperMiddleQuartileFemale)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="pl-8">Lower middle quartile</TableCell>
                <TableCell className="text-right">
                  M: {formatValue(safeResults.lowerMiddleQuartileMale)}, F: {formatValue(safeResults.lowerMiddleQuartileFemale)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="pl-8">Lower quartile</TableCell>
                <TableCell className="text-right">
                  M: {formatValue(safeResults.lowerQuartileMale)}, F: {formatValue(safeResults.lowerQuartileFemale)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}