import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null); // To track login errors
  const [loading, setLoading] = useState(true); // Add a loading state

  const navigate = useNavigate(); // Initialize useNavigate for redirection
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      verifyToken(token);
    } else {
      setLoading(false); // No token, no need to wait for auth check
    }
  }, []);

  // Verify token if present
  const verifyToken = async (token) => {
    try {
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${API_BASE_URL}/api/auth/verify-token`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setIsLoggedIn(true);
      setUsername(response.data.user.name); // Ensure response.data.user exists
      setIsAdmin(response.data.user.role === 'admin');
    } catch (error) {
      console.error('Token verification failed:', error);
      handleLogout();
    } finally {
      setLoading(false); // End loading after the token check
    }
  };

// Handle login
const handleLogin = async (email, password) => {
  try {
    setError(null); // Reset any previous errors
    setLoading(true); // Start loading during login

    // Make a POST request to your login API
    const response = await axios.post(`${API_BASE_URL}/api/auth/login`, { email, password });

    if (!response.data || !response.data.token || !response.data.user) {
      throw new Error('Invalid response from server');
    }

    const { token, user } = response.data;

    // Store the token and set headers
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // Set user information in state
    setIsLoggedIn(true);
    setUsername(user.name);
    setIsAdmin(user.role === 'admin');

    setLoading(false); // End loading after successful login
    return true; // Indicate success
  } catch (error) {
    setLoading(false); // End loading after error
    setError('Login failed. Please check your credentials or try again later.');
    return false; // Indicate failure
  }
};

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
    setUsername('');
    setIsAdmin(false);
    setLoading(false); // Ensure loading is false after logout
    // Redirect to the landing page after logout
    navigate('/');
  };

  return { 
    isLoggedIn, 
    username, 
    isAdmin, 
    error, 
    loading, // Expose loading state
    handleLogin, 
    handleLogout 
  };
};