import React from 'react'
import { ArrowRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from "../ui/button"
import { Accordion, AccordionItem } from "../ui/accordion"
import Layout from './Layout'

export default function FAQ() {
  const faqs = [
    {
      question: "What is the gender pay gap?",
      answer: "The gender pay gap is the difference between the average earnings of men and women across a workforce. It's not the same as unequal pay, which is paying men and women differently for performing the same (or similar) work.",
    },
    {
      question: "Who needs to report their gender pay gap?",
      answer: "In the UK, any organisation with 250 or more employees must publish and report specific figures about their gender pay gap. This applies to both private and public sector employers.",
    },
    {
      question: "Where can I find official guidance on gender pay gap reporting?",
      answer: (
        <>
          You can find comprehensive guidance on the UK government website. Visit the{" "}
          <a 
            href="https://www.gov.uk/guidance/gender-pay-gap-reporting-overview" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Gender pay gap reporting overview
          </a>{" "}
          for detailed information.
        </>
      ),
    },
    {
      question: "What figures do I need to calculate for gender pay gap reporting?",
      answer: (
        <>
          You need to calculate and report on:
          <ul className="list-disc pl-5 mt-2">
            <li>Mean gender pay gap in hourly pay</li>
            <li>Median gender pay gap in hourly pay</li>
            <li>Mean bonus gender pay gap</li>
            <li>Median bonus gender pay gap</li>
            <li>Proportion of males and females receiving a bonus payment</li>
            <li>Proportion of males and females in each pay quartile</li>
          </ul>
          Our calculator helps you generate all these figures accurately.
        </>
      ),
    },
    {
      question: "How do I use the Gender Pay Gap Calculator service?",
      answer: (
        <>
          To use our service:
          <ol className="list-decimal pl-5 mt-2">
            <li>Sign up for an account or log in if you already have one.</li>
            <li>Prepare your employee data in a CSV file format.</li>
            <li>Upload your CSV file to our secure platform.</li>
            <li>Our system will process your data and generate the required reports.</li>
            <li>Review your results and download the reports for submission.</li>
          </ol>
        </>
      ),
    },
    {
      question: "Is my data secure when I use your service?",
      answer: "Yes, we take data security very seriously. All data is encrypted in transit and at rest. We comply with GDPR and the Data Protection Act 2018. We do not store your raw data after processing, and we never share your information with third parties.",
    },
    {
      question: "How accurate is your calculator?",
      answer: "Our calculator uses the official methodology prescribed by the UK government for gender pay gap calculations. The accuracy of the results depends on the accuracy and completeness of the data you provide. We recommend thoroughly reviewing your input data to ensure the best results.",
    },
    {
      question: "Can I use your service if I have fewer than 250 employees?",
      answer: "Yes, while reporting is mandatory for organisations with 250 or more employees, smaller organisations can still use our service to voluntarily calculate and track their gender pay gap. This can be valuable for internal equity assessments and proactive management.",
    },
    {
      question: "How often should I calculate my organisation's gender pay gap?",
      answer: "Officially, you must report your gender pay gap figures annually if you have 250 or more employees. However, we recommend calculating it more frequently (e.g., quarterly or bi-annually) for internal monitoring and to track the impact of any initiatives you implement to address pay disparities.",
    },
  ]

  return (
    <Layout>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Frequently Asked Questions</h1>
      
      <Accordion type="single" collapsible>
        {faqs.map((faq, index) => (
          <AccordionItem 
            key={index} 
            value={`item-${index}`}
            trigger={faq.question}
            content={faq.answer}
          />
        ))}
      </Accordion>

      <div className="text-center mt-12">
        <p className="text-lg text-gray-700 mb-4">
          Can't find the answer you're looking for? Feel free to reach out to us directly.
        </p>
        <Link to="/contact">
          <Button className="bg-blue-600 hover:bg-blue-700 text-white">
            Contact Us <ArrowRight className="ml-2" size={20} />
          </Button>
        </Link>
      </div>
    </div>
    </Layout>
  )
}