import React, { useState } from 'react';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { LogIn, ClipboardList, X } from 'lucide-react';

export default function LoginSignupDialog({ onLogin }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    const success = await onLogin(email, password);

    if (success) {
      handleDialogOpenChange(false);
    } else {
      setError('Login failed. Please check your credentials.');
    }

    setIsLoading(false);
  };

  const handleJoinWaitlist = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/waitlist', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        handleDialogOpenChange(false);
        alert('You have been added to the waiting list. We will contact you soon!');
      } else {
        const data = await response.json();
        setError(data.message || 'Failed to join the waiting list. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }

    setIsLoading(false);
  };

  const handleDialogOpenChange = (open) => {
    if (!open) {
      setEmail('');
      setPassword('');
      setError(null);
    }

    setIsDialogOpen(open);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleDialogOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline">Login / Join Waitlist</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-white text-gray-900">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-gray-900">Account</DialogTitle>
          <DialogDescription className="text-gray-600">
            Login or join the waiting list for access.
          </DialogDescription>
        </DialogHeader>
        <button
          onClick={() => handleDialogOpenChange(false)}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
          aria-label="Close"
        >
          <X className="h-4 w-4" />
        </button>
        <Tabs defaultValue="login" className="w-full">
          <TabsList className="grid w-full grid-cols-2 bg-gray-100">
            <TabsTrigger value="login" className="data-[state=active]:bg-white">Login</TabsTrigger>
            <TabsTrigger value="waitlist" className="data-[state=active]:bg-white">Join Waitlist</TabsTrigger>
          </TabsList>
          <TabsContent value="login">
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <Label htmlFor="email" className="text-gray-700">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  required
                  className="bg-white border-gray-300"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="password" className="text-gray-700">Password</Label>
                <Input
                  id="password"
                  type="password"
                  required
                  className="bg-white border-gray-300"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white" disabled={isLoading}>
                <LogIn className="mr-2 h-4 w-4" /> Login
              </Button>
            </form>
          </TabsContent>
          <TabsContent value="waitlist">
            <form onSubmit={handleJoinWaitlist} className="space-y-4">
              <div>
                <Label htmlFor="waitlist-email" className="text-gray-700">Email</Label>
                <Input
                  id="waitlist-email"
                  type="email"
                  placeholder="m@example.com"
                  required
                  className="bg-white border-gray-300"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <Button type="submit" className="w-full bg-purple-600 hover:bg-purple-700 text-white" disabled={isLoading}>
                <ClipboardList className="mr-2 h-4 w-4" /> Join Waiting List
              </Button>
            </form>
          </TabsContent>
        </Tabs>
        {error && (
          <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded" role="alert">
            <p>{error}</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}