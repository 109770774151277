import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { UploadCloud, Download, ChevronRight } from 'lucide-react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
const TEMPLATE_URL = 'https://objectstore.lon1.civo.com/gpg-pub/template.csv';

export default function FileUpload({ isLoggedIn, onUploadSuccess, setError }) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);

  const handleFileChange = async (event) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setError(null); // Clear previous errors

      // Only apply row limit if the user is not logged in
      if (!isLoggedIn) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvData = e.target.result;
          const rows = csvData.split("\n");

          // Check if there are more than 6 rows (5 data + 1 header)
          if (rows.length > 6) {
            setError("File exceeds the 5-row limit for unregistered users.");
            setFile(null); // Clear file
          }
        };
        reader.readAsText(selectedFile);
      }
    }
  };

  const handleUpload = async () => {
    if (file) {
      setIsLoading(true);
      setError(null);
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(`${API_BASE_URL}/api/calculate`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        console.log('API Response:', response.data);
        onUploadSuccess(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(`An error occurred: ${error.response?.data?.message || error.message}`);
        } else {
          setError('An error occurred while processing the file. Please try again.');
        }
        console.error('Upload error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDownloadExample = async () => {
    setIsTemplateLoading(true);
    setError(null);
    try {
      const response = await fetch(TEMPLATE_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch the template');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'template.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading template:', error);
      setError('Failed to download the template. Please try again.');
    } finally {
      setIsTemplateLoading(false);
    }
  };

  // Clear error, reset file and re-trigger file processing when user logs in
  useEffect(() => {
    if (isLoggedIn) {
      setError(null); // Clear any errors
      setFile(null);  // Reset file so user can upload again after login
    }
  }, [isLoggedIn, setError]);

  return (
    <>
      <div className="mb-6">
        <Button onClick={handleDownloadExample} className="mb-6 bg-green-600 hover:bg-green-700 text-white">
          <Download className="mr-2 h-4 w-4" /> Download CSV Example {isLoggedIn ? '' : '(5 rows)'}
        </Button>
        <Label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-50">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <UploadCloud className="w-10 h-10 mb-3 text-gray-400" />
            <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500">
              CSV file (MAX. 10MB{isLoggedIn ? '' : ', 5 data rows + header'})
            </p>
          </div>
          <Input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept=".csv" />
        </Label>
      </div>
      {file &&
        <Button 
          className="w-full text-lg py-6 bg-blue-600 hover:bg-blue-700 text-white" 
          onClick={handleUpload} 
          disabled={isLoading}
        >
          {isLoading ? 'Calculating...' : 'Calculate Gender Pay Gap'}
          <ChevronRight className="ml-2 h-5 w-4" />
        </Button>
      }
      {!isLoggedIn && (
        <p className="mt-4 text-sm text-gray-600">
          Note: You are limited to 5 rows of data. Log in to remove this limitation.
        </p>
      )}
    </>
  );
}