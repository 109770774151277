// Header.js
import React from 'react';
import { Button } from "../ui/button";
import { Link } from 'react-router-dom';
import LoginSignupDialog from './LoginSignupDialog';

export default function Header({ isLoggedIn, username, onLogout, onLogin, isAdmin, loading }) {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <nav className="flex flex-wrap justify-between items-center">
          <Link to="/"><h1 className="text-2xl font-bold text-blue-600">Pay Gap Calculator</h1></Link>
          <div className="flex items-center space-x-4 mt-4 sm:mt-0">
            {loading ? (
              <span>Loading...</span>  // Placeholder while loading
            ) : isLoggedIn ? (
              <>
                <span className="text-gray-700">{username}</span>
                {isAdmin && (
                  <Link to="/admin">
                    <Button variant="outline" className="mr-2">
                      Admin
                    </Button>
                  </Link>
                )}
                <Button variant="outline" onClick={onLogout}>Logout</Button>
              </>
            ) : (
              <LoginSignupDialog onLogin={onLogin} />
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}