import React from 'react'
import { Button } from "../ui/button"
import { Link } from 'react-router-dom'
import { ArrowRight, CheckCircle } from 'lucide-react'
import Layout from './Layout'

export default function About() {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">About Gender Pay Gap Calculator</h1>
        
        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">Our Mission</h2>
          <p className="text-lg text-gray-700 mb-4">
            At Pay Gap Calculator, we're committed to promoting workplace equality and transparency. Our mission is to provide organizations with an easy-to-use tool that helps identify and address gender pay disparities.
          </p>
          <p className="text-lg text-gray-700 mb-4">
            We believe that by making pay gap analysis accessible and straightforward, we can contribute to creating fairer workplaces and closing the gender pay gap across industries.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-blue-600">1. Upload Your Data</h3>
              <p className="text-gray-700">Securely upload your company's salary data using our user-friendly interface.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-blue-600">2. Analyze</h3>
              <p className="text-gray-700">Our advanced algorithms process your data to identify any gender pay disparities.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2 text-blue-600">3. Get Insights</h3>
              <p className="text-gray-700">Receive a detailed report with actionable insights to address any identified pay gaps.</p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">Why Choose Us</h2>
          <ul className="space-y-4">
            {[
              "Easy-to-use platform",
              "Secure and confidential data handling",
              "Comprehensive analysis and reporting",
              "Actionable insights for improvement",
              "Compliance with pay equity regulations"
            ].map((feature, index) => (
              <li key={index} className="flex items-center text-lg text-gray-700">
                <CheckCircle className="text-green-500 mr-2" size={20} />
                {feature}
              </li>
            ))}
          </ul>
        </section>

        <section className="text-center">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">Ready to Get Started?</h2>
          <p className="text-lg text-gray-700 mb-6">
            Join the movement towards workplace equality. Try our Gender Pay Gap Calculator today.
          </p>
          <Link to="/">
            <Button className="bg-blue-600 hover:bg-blue-700 text-white">
              Try It Now <ArrowRight className="ml-2" size={20} />
            </Button>
          </Link>
        </section>
      </div>
    </Layout>
  )
}