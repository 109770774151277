"use client"

import React from 'react'
import { Button } from "./button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog"

interface ConfirmDialogProps {
  triggerText: React.ReactNode
  title: string
  description: string
  cancelText?: string
  confirmText?: string
  onConfirm: () => void
}

export default function ConfirmDialog({ 
  triggerText, 
  title, 
  description, 
  cancelText = "Cancel", 
  confirmText = "Confirm", 
  onConfirm 
}: ConfirmDialogProps) {
  const [open, setOpen] = React.useState(false)

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">{triggerText}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            {description}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>{cancelText}</Button>
          <Button variant="destructive" onClick={handleConfirm}>{confirmText}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}