import React from 'react'
import { Mail, FileText, HelpCircle } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Button } from "../ui/button"
import Layout from './Layout'
export default function Support() {
  const supportOptions = [
    {
      title: "FAQs",
      description: "Find answers to commonly asked questions about our service.",
      icon: <HelpCircle className="w-8 h-8 text-blue-600" />,
      action: "View FAQs",
      link: "/faq"
    },
    {
      title: "Documentation",
      description: "Browse our comprehensive documentation for detailed guidance.",
      icon: <FileText className="w-8 h-8 text-blue-600" />,
      action: "View Docs",
      link: "/documentation" // You would create a documentation page
    },
    {
      title: "Email Support",
      description: "Send us an email and we'll get back to you within 24 hours.",
      icon: <Mail className="w-8 h-8 text-blue-600" />,
      action: "Email Us",
      link: "mailto:support@genderpaygapcalculator.com"
    }
  ]

  return (
    <Layout>
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Support</h1>
      <p className="text-xl text-gray-600 text-center mb-12">
        We're here to help you with any questions or issues you may have with the Gender Pay Gap Calculator.
      </p>

      <div className="space-y-8">
        {supportOptions.map((option, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              {option.icon}
              <h2 className="text-2xl font-semibold ml-4">{option.title}</h2>
            </div>
            <p className="text-gray-600 mb-6">{option.description}</p>
            <Link to={option.link}>
              <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white">
                {option.action}
              </Button>
            </Link>
          </div>
        ))}
      </div>
    </div>
    </Layout>
  )
}